import * as React from "react"
import { StaticQuery, graphql } from "gatsby"

import Header from "./ui/header"
import Footer from "./ui/footer"

import 'bootstrap/dist/css/bootstrap.min.css';
import "./layout.css";

const Layout = (props: { children: any, noFooter?: boolean }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div style={{minHeight: "100vh"}}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{props.children}</main>
        </div>
        {props.noFooter? null : <Footer/>}
      </>
    )}
  />
)

export default Layout
