import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-tempe-arizona.png'
import image0 from "../../images/cities/scale-model-of-the-rose-garden-at-mcc-in-tempe-arizona.png"
import image1 from "../../images/cities/scale-model-of-phoenix-zoo-in-tempe-arizona.png"
import image2 from "../../images/cities/scale-model-of-asu-art-museum-in-tempe-arizona.png"
import image3 from "../../images/cities/scale-model-of-downtown-tempe-in-tempe-arizona.png"
import image4 from "../../images/cities/scale-model-of-asu-art-museum-ceramics-research-center-in-tempe-arizona.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Tempe'
            state='Arizona'
            image={image}
            lat='33.4255104'
            lon='-111.94000540000002'
            attractions={ [{"name": "The Rose Garden At MCC", "vicinity": "w, 1833 W Southern Ave, Mesa", "types": ["point_of_interest", "establishment"], "lat": 33.3927803, "lng": -111.87106160000002}, {"name": "Phoenix Zoo", "vicinity": "455 N Galvin Pkwy, Phoenix", "types": ["zoo", "point_of_interest", "establishment"], "lat": 33.4511924, "lng": -111.94803690000003}, {"name": "ASU Art Museum", "vicinity": "51 E 10th St, Tempe", "types": ["art_gallery", "museum", "point_of_interest", "establishment"], "lat": 33.418795, "lng": -111.93923000000001}, {"name": "Downtown Tempe", "vicinity": "310 S Mill Ave #A201, Tempe", "types": ["neighborhood", "political", "point_of_interest", "establishment"], "lat": 33.4271809, "lng": -111.9404652}, {"name": "ASU Art Museum Ceramics Research Center", "vicinity": "Brickyard Engineering, 699 S Mill Ave #108, Tempe", "types": ["art_gallery", "museum", "point_of_interest", "establishment"], "lat": 33.4235668, "lng": -111.93926879999998}] }
            attractionImages={ {"The Rose Garden At MCC":image0,"Phoenix Zoo":image1,"ASU Art Museum":image2,"Downtown Tempe":image3,"ASU Art Museum Ceramics Research Center":image4,} }
       />);
  }
}