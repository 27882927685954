import { Link } from "gatsby"
import * as React from "react"

const Footer = () => (
  <footer className="jumbotron" style={{backgroundColor: "#F7F8FA", marginBottom: 0}}>
    <div className="container">
        <span >
          © <Link to="/"> Titanic Design</Link>
        </span>
    </div>
  </footer>
)

export default Footer
