import * as React from "react"
import { Link } from "gatsby"

import Layout from "./layout"
import SEO from "./seo"

interface Attraction {
  "name": string;
  "vicinity": string;
  "types": string[];
  "lat": string,
  "lng": string
}

interface AttractionImage {
  [name: string]: string;
}

interface Props {
  city: string;
  state: string;
  image: string;
  lat: string;
  lon: string;
  attractions: Attraction[]
  attractionImages: AttractionImage
}

class CityPage extends React.Component<Props, {}> {
  scales: Scale[] = [100, 160, 200, 240, 480, 500, 1200]

  renderScale(scale: number) {
    return (
      <span style={{marginRight: "12px"}}>
        <sup>1</sup>
        &frasl;
        <sub>{scale}</sub>
      </span>
    );
  }

  getPath(attraction?: string): string {
    const cityName = this.susageCase(this.props.city)
    const attractionName = attraction ? `${this.susageCase(attraction)}-in-` : "";
    return `/scale-model-of-${attractionName}${cityName}.png`;
  }

  susageCase(name: string): string {
    return name.split(' ').join('-').toLowerCase()
  }

  renderAttraction(attraction: Attraction) {
    return(
      <div className="card" key={this.susageCase(attraction["name"])}>
        <img
          src={this.props.attractionImages[attraction["name"]]}
          alt={`Scale architectural model of ${attraction["name"]}`}
          className="card-img-top img-fluid"
          style={{maxWidth: "400px"}}
        />
        <div className="card-body">
          <h5 className="card-title">{attraction["name"]}</h5>
          <p className="card-text mb-2">
            {attraction["vicinity"]}.
          </p>
          <Link
            to={`/create?lon=${attraction["lng"]}&lat=${attraction["lat"]}`}
            className="card-link"
            style={{width: "250px"}}
          >
            Edit This Model
          </Link>
        </div>
      </div>
    )
  }

  color(name: string, bg: string) {
    return (
      <span
        style={{
          backgroundColor: bg,
          borderRadius: "5px",
          width: "30px",
          height: "30px",
          display: "inline-block",
          border: `1px solid ${bg === "#FAFAFA" ? "#CCC" : bg}`,
          marginRight: "6px"
        }}
        title={name}
      />
    );
  }

  render() {
    return (
      <Layout>
        <SEO title={`3D printed model of ${this.props.city}`}/>
        <div className="jumbotron" style={{backgroundColor: "#F7F8FA", padding: "2rem", marginBottom: 0}}>
          <div className="container">
            <div className="row">
              <div className="col-sm">
              <img
                src={this.props.image}
                alt={`Scale architectural model of ${this.props.city}`}
                className="img-fluid"
              />
              </div>
              <div className="col-sm-6">
                <h2>{this.props.city}, {this.props.state}</h2>
                <p className="mb-2">
                  <a href="https://titanic.design">Titanic Design's</a> model maker uses geospatial data
                   to create digital models of {this.props.city} and the surronding metropolitain area.
                   The models created are optimized for 3D printing anf are available to order as PLA and
                   available to download as STLs
                </p>
                <p className="mb-1"><b>Available Scales</b></p>
                <p className="mb-2">{this.scales.map(this.renderScale)}</p>
                <p className="mb-1"><b>Available Colors</b></p>
                {this.color("Black", "#1B1B1B")}
                {this.color("Chavant Brown", "#B2756C")}
                {this.color("Light Gray", "#A9A9A9")}
                {this.color("white", "#FAFAFA")}
                <p className="mb-2">
                  Models can be purcahsed with a color base board of the surounding area.
                  Base boards include outlines of each building to allow for easy placement
                </p>
                <div>
                  <Link
                    to={`/create?lon=${this.props.lon}&lat=${this.props.lat}`}
                    className="btn btn-primary mr-2"
                    style={{minWidth: "160px"}}
                  >
                    Create Model in {this.props.city}
                  </Link>
                  {/* <Link
                    to={`/create?lon=${this.props.lon}&lat=${this.props.lat}`}
                    className="btn btn-secondary"
                    style={{minWidth: "160px"}}
                  >
                    Create Model at my Location
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jumbotron" style={{backgroundColor: "#F7F8FA", padding: "2rem", marginBottom: 0}}>
          <div className="container">
            <h3>Popular Attractions</h3>
            <div className="card-columns">
              {this.props.attractions.map(this.renderAttraction.bind(this))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CityPage
