import { Link } from "gatsby"
import * as React from "react"

function showDropdown() {
  const div = document.getElementById('navbarNavDropdown');
  if (!div) {
    console.error("navbarNavDropdown not found");
    return
  }
  if (div.classList.contains("show")) {
    div.classList.remove("show");
  }
  else {
    div.classList.add("show")
  }
}

const Header = (props: { siteTitle: string }) => (
  <header>
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        <Link to="/">
          <h1 className="navbar-brand mb-0">{props.siteTitle}</h1>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={showDropdown}
        >
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to="/create"
                className="nav-link"
              >
                Create
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/faq"
                className="nav-link"
              >
                FAQ
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                rel="noopener"
                target="_blank"
                href="https://titanic.design/contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>

    </nav>
  </header>
)

export default Header
